let caches = new Map()
import router from '@/router'
import store from '@/store/index'


class getRouteAuth {
    constructor(title) {
        this.currentAuth = null
        this.title = title
        this._init()
    }
    get(auth) {
        if(!this.currentAuth || !this.currentAuth.children || !this.currentAuth.children.length)return false
    return this.currentAuth.children.find(item => item.title == auth)
    }
    button() {
        return this.currentAuth && this.currentAuth && this.currentAuth.children.length ? this.currentAuth.children.filter((item) => item.type == 'button' && item.name != 'visit') : []
    }
    _init() {
        if (caches.has(this.title)) { //缓存判断
            let cache = caches.get(this.title)
            this.currentAuth = cache.currentAuth
            this.value = cache.value,
            this.title = cache.title
        }else{
            this.currentAuth = getRouteAuthFunc(store.state.menu, this.title) // 获取当前页面权限
            caches.set(this.title, this) // 设置缓存
        }
    }
}

// 直接获取当前页面权限
function auth(auth, path = router.currentRoute.path) {
    return new getRouteAuth(path).get(auth)
}

// 递归判断子级是否有当前路由
function getRouteAuthFunc(arr, path) {
    let cpArr = JSON.parse(JSON.stringify(arr)) //deepcp
    let res = []
    let resRoute //匹配到的权限路由
    let noMatch = 0 //没匹配到的数量
    let nextFn = false //是否有新子级
    for (let item of cpArr) {
        if (path == item.path) {
            resRoute = item
            return resRoute
        }
        if (item.children && item.children.length) {
            nextFn = true
            res.push(item.children)
            delete item.children
        } else {
            noMatch++
        }
    }
    if (!resRoute && nextFn) {
        return getRouteAuthFunc(res.flat(10), path) // 递归拍平数组
    } else {
        if (resRoute) return resRoute
        if (noMatch == arr.length) return false
    }
}

export {
    getRouteAuth,
    auth
}